$default-font-small: 0.8em;
$default-font-medium: 1em;
$default-font-large: 1.4em;

$default-padding-small: 10px;
$default-padding-medium: $default-padding-small * 2;
$default-padding-large: $default-padding-medium * 2;

$default-margin-small: 10px;
$default-margin-medium: $default-margin-small * 2;
$default-margin-large: $default-margin-medium * 2;

//use https://coolors.co
$default-color-0: #283d3b;
$default-color-1: #197278;
$default-color-2: #edddd4;
$default-color-3: #c44536;
$default-color-4: #772e25;

$default-color-warning: fade-out(orange, 0.5);
$default-color-ok: fade-out(green, 0.5);
$default-color-error: fade-out(red, 0.5);

$default-border-radius: 4px;

@mixin button-interactive {
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 10px 1px gray;
    }

    &:active {
        transform: scale(0.9);
        box-shadow: 0 0 5px 1px gray;
    }
}
