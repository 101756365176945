@import "src/styles/vars.module";

.box {
    padding: $default-padding-small;
}

.content {
    @extend .box;

    & h1 {
    }
    & div {
        padding: $default-padding-small;
    }
}
